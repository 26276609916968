<!--
 * @Description: 账号绑定
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-17 19:02:20
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/account/binding.vue
-->
<template>
  <div class="app-wrapper account-view">
    <div class="account-nav">
      <div class="nav-item1">账号认证</div>
      <div class="nav-item2">账号绑定</div>
      <div class="nav-item3">账号建议</div>
    </div>
    <div class="account-wrapper">
      <div class="account-info">
        <div class="info-head">
          <div class="info-name">已绑定的账号</div>
          <div class="info-btn" @click="binding.show = true">绑定账号</div>
        </div>
        <div class="info-text">
          备注：为了保障视频有效的发布，如果账号绑定状态为失效，请及时重新授权绑定。
        </div>
      </div>
      <el-table
        :data="tableData"
        stripe
        max-height="500"
        style="width: 100%; margin-top: 21px"
        v-loading="isLoading"
      >
        <el-table-column fixed prop="account" label="账号" align="center">
        </el-table-column>
        <el-table-column prop="state" label="状态" align="center">
          <template slot-scope="{ row }">
            <div
              :class="[
                'state-btn',
                {
                  'state-confirm': `${row.state}` === '2',
                  'state-primary': `${row.state}` === '1',
                },
              ]"
            >
              {{ row.state_text }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="opts" label="操作" align="center">
          <!-- eslint-disable-next-line vue/no-unused-vars -->
          <template slot-scope="{ row }">
            <el-button size="mini" type="text" @click="binding.show = true"
              >重新授权</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="page.total > 0"
        :total="page.total"
        :page.sync="page.page"
        :limit.sync="page.size"
        @pagination="getList()"
      />
    </div>
    <!-- 绑定 -->
    <binding-view
      v-if="binding.show"
      :show.sync="binding.show"
      v-bind="binding"
    ></binding-view>
  </div>
</template>

<script>
import BindingView from "@/components/binding";
import { HTTP } from "../../../utils/request";
const URL = {
  list: "api/customer/userAccount/list",
};
export default {
  components: {
    BindingView,
  },
  data() {
    return {
      tableData: [],
      binding: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
          beginTime: "",
          endTime: "",
          state: "",
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.account-view {
  height: 100%;
  overflow: hidden;
  .account-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 33px;
    div {
      width: 282px;
      height: 50px;
      display: flex;
      align-items: center;
      margin-left: -10px;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      justify-content: center;
    }
    .nav-item1 {
      background-image: url("../../../assets/icon/account1.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      text-align: center;
      color: #fff;
    }
    .nav-item2 {
      background-image: url("../../../assets/icon/account2.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      text-align: center;
      color: #fff;
    }
    .nav-item3 {
      background-image: url("../../../assets/icon/account3.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      text-align: center;
      color: #7c7c7c;
    }
  }
  .account-wrapper {
    width: 840px;
    margin: auto {
      top: 45px;
    }
    .info-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .info-name {
        font-size: 20px;
        font-family: SourceHanSansCN-Bold-, SourceHanSansCN-Bold;
        font-weight: normal;
        color: #2f3356;
        line-height: 33px;
      }
      .info-btn {
        width: 96px;
        height: 33px;
        background: #f6f9ff;
        border-radius: 4px;
        opacity: 1;
        border: 1px solid #4586f0;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #4586f0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .info-text {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #7c7f97;
      margin-top: 12px;
    }
  }
}
</style>

<!--
 * @Description: 绑定手机
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-17 20:43:14
 * @FilePath: /JinChanTuoKe-Front/src/components/binding/index.vue
-->
<template>
  <div class="bind-popup" @click="shows = false">
    <div class="bind" @click.stop>
      <div class="bind-text">请扫描以下二维码绑定账号</div>
      <div class="bind-content">
        <div class="content-item">
          <iframe
            :src="url"
            frameborder="0"
            style="width: 100%; height: 100%"
          ></iframe>
        </div>

        <!-- <div class="content-left">
          <div class="left-title">快捷安全登录</div>
          <div class="left-text">
            使用<span>抖音APP</span>，打开「首页 - 扫一扫」<i
              class="el-icon-warning-outline"
            ></i>
          </div>
          <div class="left-wx">
            <div class="wx-view"></div>
          </div>
          <div class="left-tip">手机验证码登录</div>
        </div>
        <div class="content-right">
          <div class="right-title">
            致力云流量平台 <span>将获得一下权限</span>
          </div>
          <div class="right-li">
            <div class="li"></div>
            <div class="li-text">把您添加进测试应用白名单</div>
          </div>
          <div class="right-li">
            <div class="li"></div>
            <div class="li-text">获取您的抖音头像、昵称</div>
          </div>
          <div class="right-li">
            <div class="li"></div>
            <div class="li-text">获取公开视频列表能力</div>
          </div>
          <div class="right-li">
            <div class="li"></div>
            <div class="li-text">获取并管理您的视频评论</div>
          </div>
        </div> -->
      </div>
      <div class="bind-close" @click="shows = false">
        <img src="../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../utils/request";

const URL = {
  tiktokAuthorizationUrl: "api/customer/userAccount/tiktokAuthorizationUrl",
};
export default {
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },
  data() {
    return {
      url: "",
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  mounted() {
    this.tiktokAuthorizationUrl();
  },
  methods: {
    async tiktokAuthorizationUrl() {
      const res = await HTTP({
        url: URL.tiktokAuthorizationUrl,
        method: "get",
      });
      this.url = res.url;
    },
  },
};
</script>

<style lang="scss" scoped>
.bind-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  .bind {
    width: 801px;
    height: 502px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-tag {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold-, SourceHanSansCN-Bold;
      font-weight: normal;
      color: #3b3b3b;
      line-height: 24px;
      padding-left: 27px;
      margin-top: 27px;
      span {
        color: #4e6bef;
        font-weight: bold;
      }
    }
    &-content {
      width: 700px;
      height: 331px;
      //   background-color: #f2f8fa;
      //   border: 1px solid #d5d7e0;
      //   border-radius: 6px;
      margin: auto {
        top: 43px;
      }
      display: flex;
      align-items: center;
      overflow-y: auto;
      .content-item {
        width: 100%;
        height: 520px;
      }
      .content-left {
        flex: 1;
        border-right: 1px solid #d5d7e0;
        .left-title {
          font-size: 15px;
          font-weight: bold;
          text-align: center;
        }
        .left-text {
          font-size: 13px;
          color: #7c7f97;
          text-align: center;
          margin-top: 6px;
          span {
            color: red;
          }
        }
        .left-wx {
          width: 130px;
          height: 130px;
          border: 1px solid #d5d7e0;
          border-radius: 4px;
          margin: auto {
            top: 30px;
          }
          display: flex;
          align-items: center;
          justify-content: center;
          .wx-view {
            width: 110px;
            height: 110px;
            padding: 10px;
            background-color: #eee;
          }
        }
        .left-tip {
          font-size: 12px;
          color: #409eff;
          text-align: center;
          margin-top: 20px;
        }
      }
      .content-right {
        flex: 1;
        padding-left: 30px;
        margin-top: -108px;
        .right-title {
          font-size: 13px;
          font-weight: bold;
          span {
            color: #7c7f97;
          }
        }
        .right-li {
          display: flex;
          align-items: center;
          margin-top: 10px;
          .li {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #7c7f97;
            margin-right: 10px;
          }
          .li-text {
            font-size: 13px;
            font-weight: bold;
            color: #333;
          }
        }
      }
    }

    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
